import React from "react"
import { graphql } from "gatsby"
import { Row, Col } from "react-grid-system"

import Layout from "../components/layout"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"

const IndexPage = () => {
  const MapWithAMarker = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultClickableIcons={false}
        defaultZoom={15}
        defaultCenter={{ lat: 50.927016, lng: 5.341982 }}
      >
        <Marker position={{ lat: 50.927016, lng: 5.341982 }} />
      </GoogleMap>
    ))
  )

  return (
    <Layout>
      <div className="content">
        <Row>
          <Col lg={4} md={10} push={{ lg: 6 }} offset={{ lg: 1, md: 1 }}>
            <h1>Contact</h1>
            <p>Consultaties uitsluitend op afspraak</p>
            <p>
              De Gerlachestraat 20/11
              <br />
              3500 Hasselt
              <br />
              011 874874
            </p>
            <p>
              <b>Openingstijden</b>
            </p>
            <table class="price-table">
              <tr>
                <td>Maandag</td>
                <td>09.00&thinsp;–&thinsp;19.00</td>
              </tr>
              <tr>
                <td>Dinsdag</td>
                <td>09.00&thinsp;–&thinsp;19.00</td>
              </tr>
              <tr>
                <td>Woensdag</td>
                <td>14.00&thinsp;–&thinsp;19.00</td>
              </tr>
              <tr>
                <td>Donderdag</td>
                <td>09.00&thinsp;–&thinsp;19.00</td>
              </tr>
              <tr>
                <td>Vrijdag</td>
                <td>Gesloten</td>
              </tr>
              <tr>
                <td>Zaterdag (om de week)</td>
                <td>09.00&thinsp;–&thinsp;13.00</td>
              </tr>
              <tr>
                <td>Zondag</td>
                <td>Gesloten</td>
              </tr>
            </table>
          </Col>
          <Col lg={6} md={10} pull={{ lg: 6 }} offset={{ md: 1 }}>
            <MapWithAMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAx5V4QphJeFdL0i7395xFxIL5Fw5nQAIk"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `500px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
